import React, { useEffect, useState, createContext } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import Navigation from './features/navigation/Navigation';
import Logo from './features/logo';
import { supabase, useCategoriesQuery, useDepartmentsQuery, 
    usePeopleQuery, useProjectsQuery } from './app/api';
import { Projectlist } from './features/project/projectlist';
import { People } from './features/people/people';
import { NotFound } from './pages/notfound';
import { About } from './pages/about';
import { useDispatch, useSelector } from 'react-redux';
import Auth from './app/Auth';
import Account from './app/Account';
import { Home } from './home';
import { path} from 'ramda';
import { menu_open, show_info } from './app/ui_state';
import { Footer } from './features/footer/footer';
import { GraphOutlet } from './features/with_graph/graph_outlet';
import { Jobs } from './pages/jobs';
import { Categories } from './features/categories/categories';
import { News } from './pages/news';
import { Search } from './features/search';
import { Power, power_ids } from './pages/power';
import { load_timeline, load_url, resize } from './app/ui_actions';
import { Eurostudent, eurostudent_ids } from './pages/eurostudent';

export const UserContext = createContext();

const OldNewsRedirect = () => {
  const { slug } = useParams();
  return <Navigate to={`/uudised/${slug}`} replace />;
}

const App = () => {
  const [session, setSession] = useState(null);
  const menu_opened = useSelector(path(["ui", "menu_open"]));
  const info_open = useSelector(path(["ui", "show_info"]));
  const dispatch = useDispatch();
  const {data: departments} = useDepartmentsQuery();
  const {data: projects} = useProjectsQuery();
  const {data:categories} = useCategoriesQuery();
  const {data:people} = usePeopleQuery();

  const location = useLocation();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => { setSession(session) });
    supabase.auth.onAuthStateChange((_event, session) => { setSession(session) });
    dispatch(resize());
    window.addEventListener('resize', () => { dispatch(resize()) });
    if (projects) dispatch(load_timeline(projects));
  },[dispatch,projects])

  useEffect(() => {
    if (people && categories && projects && departments) {
      dispatch(load_url({people,categories,projects,departments,prev_state:location.state}));
    }
  }, [dispatch,people,categories,projects,departments,location]);

  return (
    <div>
      <UserContext.Provider value={session}>
        <Navigation />  
        <header className={menu_opened ? "opened" : ""} onClick={x => { x.stopPropagation(); dispatch(menu_open()) }}>
          <Logo color={"white"} height={30} />
          <span className="title">PRAXIS | MÕTTEKODA</span>
          <span className="menutoggle" onClick={x => { x.stopPropagation(); dispatch(show_info()) }}>
            {(info_open ? "< menüü" : "kontaktandmed >")}
          </span>
        </header>
        <main id="main">
          <Routes>
            <Route path="/praxisest" element={<About />} />
            <Route path="/konto" element={!session ? <Auth /> : <Account key={session.user.id} session={session} />} />
            <Route path="/kandideeri" element={<Jobs />} />
            <Route path="/power" element={<Power {...power_ids["ET"]}/>} />
            <Route path="/en/power" element={<Power {...power_ids["EN"]}/>} />
            <Route path="/eurostudent" element={<Eurostudent {...eurostudent_ids["EUST"]}/>} />
            <Route path="/eurostudent-eesti" element={<Eurostudent {...eurostudent_ids["EESTI"]}/>} />
            <Route path="/" element={<Home />} />
            <Route path="/tood" element={<GraphOutlet type="tood" />}>
              <Route index element={<Projectlist />} />
              <Route path=":slug">
                <Route index element={<Projectlist />} />
                <Route path=":slug" element={<Projectlist />} />
              </Route>
            </Route>
            <Route path="/inimesed" element={<GraphOutlet type="inimesed" />}>
              <Route index element={<People />} />
              <Route path=":slug" element={<People />} />
            </Route>
            <Route path="/teemad" element={<GraphOutlet type="teemad" />}>
              <Route index element={<Categories />} />
              <Route path=":slug" element={<Categories />} />
            </Route>
            <Route path="/:slug" element={<OldNewsRedirect />} />
            <Route path="/uudised">
              <Route index element={<News />} />
              <Route path=":slug" element={<News />} />
            </Route>
            <Route path="/toeta" element={<h1>Home</h1>} />
            <Route path="/tookohad" element={<h1>Home</h1>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Search/>
        <Footer />
      </UserContext.Provider>
    </div>
  );
}

export default App;
