import { indexBy, map as m, pick, filter as f, has, omit, compose as C, min, prop as p,
    apply,range,juxt,gt,__,inc, 
    values,
    reject} from 'ramda';
import { set_yearrange, set_selected, set_selected_filter, set_url, set_all_data, 
    reset_departments, set_departments, toggle_current, set_width,set_height,
    toggle_filters,toggle_map,set_svg_side,set_timeline} from './ui_state';
import { calculateYearRange, getSelection, initializeDepartments, parseUrlParams, project_in_range, 
    recalc, recalcAndUpdate, setFilters, setUrl, yearrange_selection } from './ui_utils';

export const setSelected = ([val, navigate, path, history]) => (dispatch, getState) => {
  const { api: { queries }, ui: { all_data } } = getState();
  const value = val?.selected_obj || val;
  const selection = all_data && value?.slug ? all_data.find(x => x.slug === value.slug) : value;  
  const categories_data = !val ? {} :
    pick(["year_ranges"], indexBy(p("slug"), queries?.["categories(undefined)"]?.data || [])?.[val] || {});
  dispatch(set_selected(val?.slug ? { ...val, ...selection, ...categories_data } : undefined));
  dispatch(setUrl([val?.selected_obj ? omit(["selected_obj"], val) :
    { selected: val ? (val.selected || val.ui_id || val.id) : undefined }, navigate, path, history]));
};

export const setSelectedFilter = ([val, navigate, path, history, type]) => (dispatch, getState) => {
  const { ui, api: { queries } } = getState();
  const { is_full_range, reset_range, full_range, in_range, initial_range } = 
    yearrange_selection(ui, type);
  const right_path = (reset_range && type !== "all") ? ui.url.path.split("/").slice(0, 2).join("/") : path;
  const only_active = (ui.only_current && ui.selected?.group === "inimesed") ? ui.selected.active : true;
  const selected = {selected: val ? (val.ui_id || val.id) : undefined};
  const url_params = !only_active ? selected : {
    ...(in_range ? {} : selected),
    ...(type === "all" ? full_range : (val === undefined && is_full_range) ? initial_range : {}),
    filter: type};
  const selection = ui.all_data && val?.slug ? ui.all_data.find(x => x.slug === val.slug) : val;  
  const categories_data = !val ? {} :
    pick(["year_ranges"], indexBy(p("slug"), queries?.["categories(undefined)"]?.data || [])?.[val] || {});
  const sel = val?.slug ? { ...val, ...selection, ...categories_data } : undefined;

  if (type === "all") dispatch(set_yearrange(full_range));
  if ((val === undefined && is_full_range)) dispatch(set_yearrange(initial_range));
  dispatch(set_selected_filter(type));
  dispatch(recalc(getState()));
  dispatch(setFilters());
  dispatch(setUrl([url_params, navigate, right_path, history]));
  if (!only_active) dispatch(set_selected(sel));
};

export const setTopics = ([val, navigate, path, history]) => (dispatch, getState) => {
    dispatch(set_departments(val));
    const { ui: { departments } } = getState();
    const department_ids = C(values, m(p("id")), reject(p("show")))(departments);
    dispatch(setUrl([{ topics: department_ids }, navigate, path, history ]));
    dispatch(recalc(getState()));
    dispatch(setFilters());
  };
export const resetTopics = ([val, navigate, path, history]) => (dispatch, getState) => {
    const department_ids = C(values, m(p("id")), reject(p("show")))(val);
    dispatch(reset_departments(val));
    dispatch(setUrl([{ topics: department_ids }, navigate, path, history ]));
    dispatch(recalc(getState()));
    dispatch(setFilters());
  };
export const setAuthors = recalcAndUpdate(val => toggle_current());
export const setPeriod = ([val, navigate, path, history]) => (dispatch, getState) => {
    const { ui} = getState();
    const {in_range,is_full_range,initial_range} = yearrange_selection(ui, "range");
    
    const right_path = !in_range ? ui.url.path.split("/").slice(0, 2).join("/") : path;

    if (!val || is_full_range) {
        dispatch(set_selected_filter(undefined));}
    if (!in_range) {
        dispatch(set_selected(undefined));
    }
    dispatch(set_yearrange(val || initial_range));
    dispatch(setUrl([{...(val || initial_range),
        ...(!in_range ? {selected:undefined}: {}),
        ...((!val || is_full_range) ? {filter:undefined} : {})
    }, navigate, right_path, history ]));
    dispatch(recalc(getState()));
    dispatch(setFilters());
  };


export const load_timeline = (projects) => (dispatch) => {
  const timeline = C(
    apply(range),
    juxt([
      C(apply(Math.min), f(gt(__, 1)), m(p("start"))),
      C(inc, apply(Math.max), m(p("end")))])
  )(projects);
  dispatch(set_timeline(timeline));
};

export const resize = () => (dispatch, getState) => {
    const h = document.clientHeight || window.innerHeight;
    const w = document.clientWidth || window.innerWidth;
    dispatch(set_width(w));
    dispatch(set_height(h));
    dispatch(toggle_filters(w > 1200));
    dispatch(toggle_map(w > 1200));
    dispatch(set_svg_side(min(w, h)));
};

export const load_url = ({ projects, categories, people, departments, prev_state }) => (dispatch, getState) => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = prev_state || parseUrlParams(urlParams);
  const allData = [...people, ...categories, ...projects];
  const [, , project, tab] = window.location.pathname.split("/");
  const { ui: { timeline_range } } = getState();
  const selection = getSelection(allData, params, project, tab);
  const yearrange = calculateYearRange(params, timeline_range, selection);

                                    dispatch(set_all_data(allData));
                                    dispatch(reset_departments(initializeDepartments(departments, params)));
                                    dispatch(set_yearrange(yearrange));
  if (prev_state || urlParams.size) dispatch(set_url({ params, path: window.location.pathname }));
  if (prev_state || params.authors) dispatch(toggle_current(!params.authors));
  if (prev_state || params.filter)  dispatch(set_selected_filter(params.filter));
  if (prev_state || selection)      dispatch(set_selected(selection));
  if (prev_state || urlParams.size) dispatch(setFilters());
                                    dispatch(recalc(getState()));
};