import ReactMarkdown from "react-markdown";
import sectionize from 'remark-sectionize'
import * as Yup from 'yup';
import { compose as C, map as m, prop as p, __, defaultTo, indexOf,concat, join, tap, 
    props, path, sort, ascend, descend, sortBy,
    includes} from "ramda";
import { useProjectsQuery,useUpdateProjectMutation } from "../../app/api";
import { EditableCollapsible } from "../../pages/part";
import { EditableText } from "../../pages/EditableText";
import { Authors } from "./authors";
import { CategoryIcons } from "./category_icons";
import { Years } from "./years";
import "./project.css";
import './../../pages/part.css';
import { add_size, slug } from "../helpers";
import { useSelector } from "react-redux";
import { AppLink } from "../navigation/applink";

export const ProjectTabs = ({main,selected,closed}) => {
    const params = useSelector(path(["ui","url"]));
    const {data} = useProjectsQuery(main.children?.length ? main.children.map(x => x.id) : "empty-array");
    const is_main = (n,xs) => (n === (xs.length-1) || xs.length === 1);
    const is_open = x => selected?.slug === x.slug;
    const minimized = (n,xs) => {
        return (closed || !includes("/",selected?.slug)) ? 0 : (C(indexOf(selected?.slug),m(p("slug")),defaultTo([]))(xs) < n);
    }
    const add_class = (x,n,xs) => {
        return join(" ",[... (is_open(x) ? ["open_tab"] : minimized(n,xs) ? ["minimized"] : []),
            ...(is_main(n,xs) ? ["project"] : ["subproject"])])
    } 
    const RenderProject = ({data}) => <>
        <AppLink to="/tood" selected={undefined} className="project_back">
            <img src="/back.svg" alt="backbutton" className="back"/>
        </AppLink>
        <div className="project_categories">{C(
            m((x) => 
            <AppLink selected={x} key={`icon_${x.id}+${data.id}`} className="category_link" to={`/teemad/${x.slug}`}>
                <img src={`/uploads/ui/${x.icon}`} decoding="async" loading="lazy" className="category"/>
                <span>{x.name}</span>
            </AppLink>),
            sort((a, b) => (a.color === data.color && b.color !== data.color) ? -1 :
                (a.color !== data.color && b.color === data.color) ? 1 :
                a.color.localeCompare(b.color)),
            m(x => ({...x,...x.categories})),
        )(data.Projekt_Kategooria)}
        </div>
        <h2>{data.name}</h2>
        <div className="project_authors">{C(
            m(({people:x}) => {
            const fullname = C(join(" "),props(["firstname","lastname"]))(x)
            return x.active ?     
            <AppLink 
                selected={x}
                key={slug(fullname)+data.id}
                to={`/inimesed/${x.slug}`}>
                {x.pilt ? <img src={add_size(40,x.pilt)} title={fullname} 
                    alt={fullname}  decoding="async" className="avatar" loading="lazy" 
                    sizes="(max-width: 40px) 100vw, 40px"  
                    srcSet={`${add_size(40,x.pilt)} 40w, ${add_size(120,x.pilt)} 120w`} /> : ""}
                <span>{fullname}</span>
            </AppLink>
            : <span key={data.id+fullname}>{fullname}</span>}),
        sort(descend(path(["people","active"]))),
        )(data.Projekt_Inimene)}
        </div>

        <ReactMarkdown  className="formattedText"  remarkPlugins={[sectionize]}  children={data.content} />
        <div className="failid closed">{m(x => 
            <a rel="noreferer" key={"file_"+x.id+"_"+data.id} target="_blank" href={x.url}><span>{x.type}</span>{x.title}</a>,
        (data.Projekt_Projektifailid || []))}
        </div>
    </>


    return params && data ? <div className="tabs">
        {concat([main],sort(descend(p("start")),data) || []).reverse().map((x,n,xs) => {
        return <div key={x.slug} className={add_class(x,n,xs)} 
            style={{
                bottom:(xs.length-n-1)*(closed? 4 : 60), 
                height: (!is_open(x) && minimized(n,xs)) ? 160 : "100%",
                left:-(xs.length-n-1)*4}}>
            {!is_open(x) ? <>
                <AppLink className="projectlink" selected={is_main(n,xs) ? main : x} to={`/tood/${(is_main(n,xs) ? main : x).slug}/`}/>
                {is_main(n,xs) ? <h2>{x?.name}</h2> : <h3>{x?.name}</h3>}
            </> : 
            <EditableCollapsible 
                slug={x.slug}
                key={x.slug}
                collapsed={closed}
                className="singleproject"
                validations={{projectname: Yup.string(),content: Yup.string()}}
                queryFn={useProjectsQuery}
                mutationFn={useUpdateProjectMutation}
                FormElements={({data}) => <>
                    <EditableText name="projectname" className="h1_input"/>
                    <EditableText name="content"/>
                </>}
                RenderElements={({data}) => <RenderProject 
                    key={(includes("/",selected.slug) ? x : data[0]).id} 
                    data={includes("/",selected.slug) ? x : data[0]}/>}/>}
            <Authors data={x}/>
            <Years start={x.start} end={x.end}/>
            <CategoryIcons data={main}/>
        </div>})}
    </div> : null
}

